.share-app {
  display: flex;
  height: 100vh;
  flex-direction: column;
  //   justify-content: center;
  align-items: center;
  background-color: #1e2e3d;
}

.share-app--image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-top: 10%;

  img {
    border-radius: 20px;
    width: 100px;
    height: 100px;
  }
}

.share-app--label {
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
}
